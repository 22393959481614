import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

export default class {
  constructor() {
    this.lastScrollTop = 0;
    this.scrollUpDetection = true;
    this.scrollOffset = 1;
    this.scrollUpOffset = window.innerHeight / 4;
    this.mainMenuSelector = '.header';
    this.mainMenuScrollClass = 'header--scroll';
    this.mainMenuScrollUpClass = 'header--scroll-up';
    this.mainMenuElem = document.querySelector(this.mainMenuSelector);
    this.scroll = '.js-scroll'

    if (!this.mainMenuElem) {
      return false;
    }

    this.mainMenuOnScroll();
    this.onScroll();
    this.updateUrl();
    this.initScrollTo();

    window.addEventListener('load', () => {
      this.initScrollSpy();
      this.initScrollDown();
    });
  }

  onScroll() {
    this.scrollTop = (document.body.scrollTop || document.documentElement.scrollTop) || 0;
    this.lastScrollTop = this.scrollTop;

    window.addEventListener('scroll', () => {
      this.mainMenuOnScroll();
    });
  }

  mainMenuOnScroll() {
    this.scrollTop = (document.body.scrollTop || document.documentElement.scrollTop) || 0;

    // Change the sticky header styling on scroll
    if (this.scrollTop > this.scrollOffset) {
      this.mainMenuElem.classList.add(this.mainMenuScrollClass);
    } else {
      this.lastScrollTop = -1;
      this.mainMenuElem.classList.remove(this.mainMenuScrollClass);
    }

    if (!this.scrollUpDetection) {
      return false;
    }

    // Check whether the class should be added on scroll up
    this.mainMenuElem.classList.remove(this.mainMenuScrollUpClass);

    let goingUp = (this.scrollTop < this.lastScrollTop) && (this.scrollTop > this.scrollUpOffset);

    if (goingUp) {
      this.mainMenuElem.classList.add(this.mainMenuScrollUpClass);
    }

    this.lastScrollTop = this.scrollTop;
  }

  resetScrollClasses() {
    this.mainMenuElem.classList.remove(this.mainMenuScrollUpClass);
  }

  initScrollDown() {
    const hero = document.querySelector('.hero');
    if (!hero) {
      return;
    }

    const link = hero.querySelector('.js-scroll-down');

    if (!link) {
      return;
    }

    let offsetTop = window.innerWidth < 600 ? 124 : 135;

    link.addEventListener('click', () => {
      let offset = hero.offsetHeight - offsetTop;

      gsap.to(window, {
        duration: 1,
        scrollTo:{
          y: offset,
          x: 0,
        },
      });
    })
  }

  updateUrl() {
    const anchors = document.querySelectorAll(this.scroll);
    const removeLastPath = (url) => {
      const urlArray = url.split('/');
      urlArray.pop();
      return( urlArray.join('/'));
    }

    anchors.forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        let offsetTop = window.innerWidth < 600 ? 124 : 135;
        if (anchor.getAttribute('href') !== '#') {
          const scrollToElement = document.getElementById(anchor.getAttribute('href').substring(2));

          if (scrollToElement) {
            const offset = scrollToElement.offsetTop - offsetTop;

            gsap.to(window, {
              duration: 1,
              scrollTo:{
                y: offset,
                x: 0,
              },
            });
          } else {
            const path = removeLastPath(window.location.pathname)
            window.location = `${path}${anchor.getAttribute('href')}`;
          }
        }
      });
    });
  }

  initScrollTo() {
    let offsetTop = window.innerWidth < 600 ? 124 : 135;
    const pageURL = window.location.href;
    const lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);
    if (lastURLSegment !== '#') {
      const scrollToElement = document.getElementById(lastURLSegment.substring(1));

      if (scrollToElement) {
        const offset = scrollToElement.offsetTop - offsetTop;

        gsap.to(window, {
          duration: 1,
          scrollTo:{
            y: offset,
            x: 0,
          },
        });
      }
    }
  }

  initScrollSpy() {
    const sectionLinks = document.querySelectorAll(this.scroll);
    let offsetTop = window.innerWidth < 600 ? 124 : 135;

    window.addEventListener('scroll', () => {
      let fromTop = window.scrollY;

      sectionLinks.forEach(link => {
        const section = document.getElementById(link.hash.substring(1));

        if (!section) {
          return;
        }

        const offset = section.offsetTop - offsetTop;

        if (
          offset <= fromTop &&
          offset + section.offsetHeight > fromTop
        ) {
          link.parentElement.classList.add('active');
        } else {
          link.parentElement.classList.remove('active');
        }
      });
    });
  }
}
