import lottie from 'lottie-web';

export default class {
  constructor() {
    this.iconAnimationInit();
    this.mapAnimationInit();
  }

  iconAnimationInit() {
    const elements = document.querySelectorAll('.js-icon-animation');

    if (!lottie || !elements) {
      console.warn('Lottie.js not defined!');
      return false;
    }

    elements.forEach(element => {
      const jsonSrc = element.dataset.jsonSrc;
      const animationElement = lottie.loadAnimation({
        name: 'icon',
        container: element, // the dom element that will contain the animation
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: jsonSrc,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet', // Supports the same options as the svg element's preserveAspectRatio property
        },
      });

      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];

        if (entry.isIntersecting) {
          animationElement.play()
        }

      }, { threshold: 0 });

      observer.observe(element);
    })
  }

  mapAnimationInit() {
    const elements = document.querySelectorAll('.js-map-animation');

    if (!lottie || !elements) {
      console.warn('Lottie.js not defined!');
      return false;
    }

    elements.forEach(element => {
      const jsonSrc = element.dataset.jsonSrc;
      const animationElement = lottie.loadAnimation({
        name: 'icon',
        container: element, // the dom element that will contain the animation
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: jsonSrc,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet', // Supports the same options as the svg element's preserveAspectRatio property
        },
      });

      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];

        if (entry.isIntersecting) {
          animationElement.play()
        }

      }, { threshold: 0 });

      observer.observe(element);
    })
  }
}
