import '../styles/main.scss';

import LazyLoad from './components/lazy-load';
import MobileMenu from './components/mobile-menu';
import ScrollActions from './components/scroll-actions';
import Accordion from './components/accordion.js';
import Slider from './components/slider';
import Animation from './components/animation';
import CookieConsent from './components/cookie-consent.js';

window.addEventListener('DOMContentLoaded', () => {
  new LazyLoad();
  new MobileMenu();
  new ScrollActions();
  new Accordion();
  new Slider();
  new Animation();
  new CookieConsent();
});
