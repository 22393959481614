import Cookies from 'js-cookie'

class PermissionsHelper {
  constructor() {
    throw new Error('Cannot construct singleton');
  }

  static permitAnalytics() {
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){ // eslint-disable-line
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o), // eslint-disable-line
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m) // eslint-disable-line
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga') // eslint-disable-line

    window.ga('create', 'UA-158265733-1', 'auto');
    window.ga('set', 'anonymizeIp', true);
    window.ga('send', 'pageview');

  }

  static prohibitAnalytics() {
    window['ga-disable-UA-158265733-1'] = true;
  }

  static set analyticsPermitted(val) {
    Cookies.set('adviseAnalyticsEnabled', val, { expires: 30 });
    if (val === 0) {
      PermissionsHelper.prohibitAnalytics()
    } else {
      PermissionsHelper.permitAnalytics()
    }
  }

  static get analyticsPermitted() {
    return Cookies.get('adviseAnalyticsEnabled');
  }
}

export default PermissionsHelper
