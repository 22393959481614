import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);

export default class {
  constructor() {
    this.options = {
      testimonials: '.js-testimonials',
      news: '.js-news-slider',
      carousel: '.js-carousel-slider',
    };

    this.init();
  }

  init() {
    this.testimonialsSlider();
    this.newsSlider();
    this.carouselSlider();
  }

  testimonialsSlider() {
    const testimonials = document.querySelectorAll(this.options.testimonials);

    if (!testimonials) {
      return
    }

    testimonials.forEach(testimonial => {
      new Swiper(testimonial, {
        slidesPerView: 1,
        spaceBetween: 30,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.testimonials__pagination',
          clickable: true,
        },
      })
    })
  }

  newsSlider() {
    const news = document.querySelectorAll(this.options.news);

    if (!news) {
      return
    }

    news.forEach(item => {
      new Swiper(item, {
        slidesPerView: 1,
        observer: true,
        spaceBetween: 30,
        grabCursor: true,
        navigation: {
          nextEl: '.news--next',
          prevEl: '.news--prev',
        },
        breakpoints: {
          600: {
            grabCursor: false,
            allowTouchMove: false,
            slidesPerView: 3,
            spaceBetween: 30,
          },
        },
      })
    })
  }

  carouselSlider() {
    const items = document.querySelectorAll(this.options.carousel);

    if (!items) {
      return
    }

    items.forEach(item => {
      new Swiper(item, {
        slidesPerView: 1,
        observer: true,
        centeredSlides: true,
        spaceBetween: 30,
        initialSlide: 1,
        loop: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
        },
      })
    })
  }
}
