import PermissionsHelper from '../helper/permissions'

export default class {
  constructor() {
    this.cookieLayer = document.querySelector('.js-cookie-layer');
    this.cancelButton = this.cookieLayer.querySelector('.js-cookie-cancel');
    this.acceptButton = this.cookieLayer.querySelector('.js-cookie-accept');
    if (PermissionsHelper.analyticsPermitted === undefined) {
      this.init();
    } else if(PermissionsHelper.analyticsPermitted !== '0') {
      PermissionsHelper.permitAnalytics();
    }
  }
  init() {
    this.cookieLayer.style.display = 'block';

    this.acceptButton.addEventListener('click', () => {
      PermissionsHelper.analyticsPermitted = 1
      this.cookieLayer.style.display = 'none';
    })
    this.cancelButton.addEventListener('click', () => {
      PermissionsHelper.analyticsPermitted = 0
      this.cookieLayer.style.display = 'none';
    })
  }
}
