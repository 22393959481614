export default class MobileMenu {
  constructor() {
    this.menuToggleSelector = '#mobile-menu-toggle';
    this.bodyMenuOpenClass = 'mobile-menu-open';
    this.menuToggleOpenClass = 'is-active';
    this.menuToggleElem = document.querySelector(this.menuToggleSelector);

    if (!this.menuToggleElem) {
      return false;
    }

    this.init();
  }

  init() {
    this.menuToggleElem.addEventListener('click', () => {
      this.menuToggleElem.classList.toggle(this.menuToggleOpenClass);
      document.body.classList.toggle(this.bodyMenuOpenClass);
    })

    const menuLinks = document.querySelectorAll('.navigation__link');
    if (!menuLinks) {
      return
    }

    menuLinks.forEach(link => {
      link.addEventListener('click', () => {
        if (this.menuToggleElem.classList.contains(this.menuToggleOpenClass)) {
          this.menuToggleElem.classList.remove(this.menuToggleOpenClass);
          document.body.classList.remove(this.bodyMenuOpenClass);
        }
      })
    })
  }
}
