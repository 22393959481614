import lozad from 'lozad';

export default class LazyLoad {
  constructor() {
    this.init();
  }

  init() {
    lozad('.lozad', {
      rootMargin: '50% 0px',
    }).observe();
  }
}
