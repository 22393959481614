import {debounce} from '../util/debounce';

export default class {
  constructor() {
    this.selectors = {
      accordion: '.js-accordion',
      item: '.js-accordion-item',
      trigger: '.js-accordion-trigger',
      content: '.js-accordion-content',
    };
    this.stateClass = 'is-expanded';

    this.init();
  }

  init() {
    const accordions = document.querySelectorAll(this.selectors.accordion);

    if (!accordions) {
      return
    }

    accordions.forEach(accordion => {
      const items = accordion.querySelectorAll(this.selectors.item);

      if (!items) {
        return
      }

      items.forEach(item => {
        const trigger = item.querySelector(this.selectors.trigger);
        const content = item.querySelector(this.selectors.content);

        if (!trigger) {
          return;
        }

        trigger.addEventListener('click', (e) => {
          e.preventDefault();

          if (item.classList.contains(this.stateClass)) {
            item.classList.remove(this.stateClass);
            content.style.maxHeight = null
          } else {
            items.forEach(item => {
              const content = item.querySelector(this.selectors.content);
              content.style.maxHeight = null;
              void(item.classList.contains(this.stateClass) && item.classList.remove(this.stateClass));
            });

            content.style.maxHeight = `${content.scrollHeight}px`;
            item.classList.add(this.stateClass);

            window.addEventListener('resize', debounce(() => {
              if (item.classList.contains(this.stateClass)) {
                content.style.maxHeight = `${content.scrollHeight}px`;
              }
            }))
          }
        });
      })
    })
  }
}
